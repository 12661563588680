<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h1 class="display-4">{{ actionString }} Disease History</h1>
          </div>
        </div>
      </div>
      <div class="p-4">
        <div class="row">
          <div class="col-lg-5 col-md-12">
            <base-input
              label="Date"
              input-classes="form-control"
              type="date"
              v-model="itemData.date"
              :disabled="viewOnly"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6 form-group">
            <label class="form-control-label">Disease</label>
            <select
              class="rounded m-0 form-control"
              v-model="itemData.dieseas"
              :disabled="viewOnly"
            >
              <option
                v-for="(disease, index) in diseaseList"
                :key="index"
                :value="disease.id"
              >
                {{ disease.dieseas_name }}
              </option>
            </select>
          </div>
           <div class="col-6 form-group">
            <label class="form-control-label"> Pest Attack</label>
            <select
              class="rounded m-0 form-control"
              v-model="itemData.pest_attack"
              :disabled="viewOnly"
            >
              <option
                v-for="(pest, index) in pestAndAttacks"
                :key="index"
                :value="pest.id"
              >
                {{ pest.pest_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label class="form-control-label">Precautions:</label>
            <textarea
              class="form-control"
              rows="8"
              @input="watchBullets1()"
              v-model="itemData.precautions"
              :disabled="viewOnly"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label class="form-control-label">Details:</label>
            <textarea
              class="form-control"
              rows="8"
              @input="watchBullets()"
              v-model="itemData.details"
              :disabled="viewOnly"
            ></textarea>
          </div>
        </div>
        <div class="button-bar text-right mt-4">
          <button
            class="btn btn-danger"
            @click.prevent="goBackToParent"
            v-if="!viewOnly"
          >
            Cancel
          </button>
          <button class="btn btn-success" @click.prevent="performAction">
            Done
          </button>
        </div>
      </div>
      <loader :displayLoader="displayLoader"></loader>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-disease-history",
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    crop: {
			type: Number,
		},
		landID: {
			type: Number,
		},
    editItem: {
      type: Object,
      default: undefined,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
  },
  data() {
    return {
      displayLoader: false,
      itemData: {
        date: "",
        dieseas: null,
        pest_attack:null,
        precautions: "• ",
        details: "• ",
        land_id:null,
        crop_id:null
      },
    };
  },
  methods: {
    performAction() {
      if (!this.viewOnly) {
        if (this.inputHasErrors()) {
          return;
        }

        this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					diseaseHistoryData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						diseaseHistoryData: this.itemData,
						index: this.editItem.index
					};
				}
        this.itemData.land_id=this.landID;
        this.itemData.crop_id=this.selectedCrop

				this.$store
					.dispatch("setDiseaseHistoryDetails", {
						farmerID: this.farmerID,
						actionObject: actionObject
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " disease",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
    },
    watchBullets() {
      var str = this.itemData.details;
      if (str[str.length - 1] == "\n" || str.length == 0) {
        this.itemData.details += "• ";
      }
    },
    watchBullets1() {
      var str = this.itemData.precautions;
      if (str[str.length - 1] == "\n" || str.length == 0) {
        this.itemData.precautions += "• ";
      }
    },

    inputHasErrors() {
      var invalidValue = false;
      if (this.itemData.date == "") {
        invalidValue = true;
      }if (this.itemData.date.length>10) {
        invalidValue = true;
      }
      if (this.itemData.dieseas == "") {
        invalidValue = true;
      }
      if (this.itemData.precautions == "") {
        invalidValue = true;
      }
      if (this.itemData.details == "") {
        invalidValue = true;
      }

      if (invalidValue) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: "Please enter valid data for all fields",
        });
      }

      return invalidValue;
    },

    goBackToParent() {
      this.$router.back();
    },
    fetchDiseaseHistoryDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchDiseaseHistoryDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
          this.itemData.land = this.landID;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch disease details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
    fetchDiseaseList(){
      this.displayLoader = true;
			this.$store
				.dispatch("fetchDiseasesList", {crop:this.crop,searchQuery:''})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch disease list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
    },
    fetchPestsAndAttacksList(crop) {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchPestsAndAttacksList",{crop:crop,searchQuery:""})
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch pests and attacks",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
  },

  computed: {
    tableData() {
      return this.$store.state.farmerData.diseaseHistoryData.tableData;
    },
    diseaseList() {
      return this.$store.state.cropData.diseases.tableData
    },
    pestAndAttacks(){
      return this.$store.state.cropData.pestsAndAttacks.tableData;
    },
    currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
    // diseaseList() {
    //   return this.$store.state.farmerData.diseaseHistoryData.diseases;
    // },
    actionString() {
      if (this.editItem) {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
  watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				},
        selectedCrop(){
          this.fetchPestsAndAttacksList(this.selectedCrop);
          this.fetchDiseaseList();
        }
	},
  mounted() {
    if (this.crop === undefined || this.landID === undefined) {
      // Lost crop and land info; go back to restore them
      this.goBackToParent();
      return;
    }
    if(this.selectedCrop !=undefined){
		this.fetchPestsAndAttacksList(this.selectedCrop);
    this.fetchDiseaseList();
		}
    
    if (this.editItem) {
      this.fetchDiseaseHistoryDetails();
      this.itemData = this.currentItem;
    }

  }
};
</script>
<style lang="scss" scoped></style>