<template>
	<div>
		<base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-success opacity-8"></span>
			<!-- Header container -->
			<div class="container-fluid align-items-center">
				<div class="row">
					<div class="col">
						<h1 class="display-2 text-white">Profile</h1>
						<p class="text-white mt-0 mb-0">
							Your profile information
						</p>
						<!-- <a href="#!" class="btn btn-info">Edit profile</a> -->
					</div>
				</div>
			</div>
		</base-header>

		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<card shadow type="secondary">
						<div slot="header" class="bg-white border-0">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">My account</h3>
								</div>
								<div class="col-4 text-right">
									<img
										:src="$store.state.accountData.profilePicture"
										class="rounded-circle"
										width="50px"
									/>
								</div>
							</div>
						</div>
						<template>
							<form @submit.prevent>
								<h6 class="heading-small text-muted mb-4">
									User information
								</h6>
								<div class="pl-lg-4">
									<div class="row">
										<div class="col-lg-6">
											<base-input
												alternative=""
												label="Username"
												input-classes="form-control-alternative"
												v-model="$store.state.accountData.username"
												:disabled="true"
											/>
										</div>
										<div class="col-lg-6">
											<base-input
												alternative=""
												label="User ID"
												input-classes="form-control-alternative"
												v-model="$store.state.accountData.userID"
												:disabled="true"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<base-input
												alternative=""
												label="Name"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.name"
												:disabled="true"
											/>
										</div>
										<div class="col-lg-6">
											<base-input
												alternative=""
												label="User type"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.usertype"
												:disabled="true"
											/>
										</div>
									</div>
								</div>
								<hr class="my-4" />
								<h6 class="heading-small text-muted mb-4">
									Contact information
								</h6>
								<div class="pl-lg-4">
									<div class="row">
										<div class="col-md-12">
											<base-input
												alternative=""
												label="Address"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.address"
												:disabled="true"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-4">
											<base-input
												alternative=""
												label="Email ID"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.email"
												:disabled="true"
											/>
										</div>
										<div class="col-lg-4">
											<base-input
												alternative=""
												label="Mobile number"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.mobile_no"
												:disabled="true"
											/>
										</div>
										<div class="col-lg-4">
											<base-input
												alternative=""
												label="DOB"
												input-classes="form-control-alternative"
												v-model="$store.state.profileData.dob"
												:disabled="true"
											/>
										</div>
									</div>
								</div>
							</form>
						</template>
					</card>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: "user-profile",
	methods: {
		fetchUserInfo() {
			this.$store.dispatch("fetchProfileData");
		}
	},
	data() {
		return {
		};
	},
	mounted() {
		if (this.$store.state.profileData.name == 'Loading...') {
			this.fetchUserInfo();
		}
	}
};
</script>
<style></style>
