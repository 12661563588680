<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header">
					<div class="row text-center">
						<div class="col-12">
							<h1>Disease - {{ actionString }}</h1>
						</div>
					</div>
				</div>

				<div class="pl-lg-4 mt-4">
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label"> Name </label>
							<!-- <select
                class="rounded p-2 w-100 m-0 form-control"
                v-model="itemData.name"
                :disabled="viewOnly"
              >
                <option>Disease 1</option>
                <option>Disease 2</option>
                <option>Disease 3</option>
              </select> -->
							<base-input
							
								input-classes="form-control"
								v-model="itemData.dieseas_name"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label"> Symptoms </label>
							<textarea
								class="form-control"
								rows="5"
								@input="watchBullets()"
								v-model="itemData.symptoms"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6">
							<label class="form-control-label"> Cause </label>
							<select
								class="rounded p-2 w-100 m-0 form-control"
								v-model="itemData.cause"
								:disabled="viewOnly"
							>
								<option value="pest">Pest</option>
								<option value="nutrient">Nutrient</option>
							</select>
						</div>
						<div class="col-lg-6">
							<label class="form-control-label">
								Nutrient Cause Type
							</label>
							<select
								class="rounded p-2 w-100 m-0 form-control"
								v-model="itemData.nutrient_cause_type"
								:disabled="viewOnly"
							>
								<option value="nutrient_deficiency">
									Nutrient Deficiency
								</option>
								<option value="poison">Poison</option>
							</select>
						</div>
						<!-- <div class="col-lg-6">
              <base-input
                label="Cause details"
                input-classes="form-control"
                v-model="itemData.causeDetails"
                :disabled="viewOnly"
              />
            </div> -->
					</div>
					<div class="row mt-3">
						<div class="col-6 form-group">
							<label class="form-control-label">
								Nutrient cause
							</label>
							<select
								class="rounded p-2 w-100 m-0 form-control"
								v-model="itemData.nutrient_cause"
								:disabled="viewOnly"
							>
								<option selected disabled value="">
									Choose
								</option>
								<option
									v-for="(item, idx) in $store.state.cropData
										.diseases.nutrientCause"
									:key="idx"
									:value="item.id"
								>
									{{ item.nutrient_name }}
								</option>
							</select>
						</div>
						<div class="col-lg-6">
							<!-- <base-input
                label="Remedy"
                input-classes="form-control"
                v-model="itemData.remedy"
                :disabled="viewOnly"
              /> -->
							<label class="form-control-label">
								Pest Cause
							</label>
							<select
								class="rounded p-2 w-100 form-control"
								v-model="itemData.pest_cause"
								:disabled="viewOnly"
							>
								<option selected disabled value="">
									Choose
								</option>
								<option
									v-for="(item, idx) in $store.state.cropData
										.diseases.pestCause"
									:key="idx"
									:value="item.id"
								>
									{{ item.pest_name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<!-- <div class="col-lg-6">
              <base-input
                label="Remedy details"
                input-classes="form-control"
                v-model="itemData.remedyDetails"
                :disabled="viewOnly"
              />
            </div> -->
					</div>
					<div class="row">
						<div class="col-12">
							<input
              v-if="itemData.id ===undefined"
								label="File upload"
								input-classes="form-control"
								type="file"
								:disabled="viewOnly"
								@change="onFileChange"
							/>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						@click.prevent="$router.back()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "diseases-add",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined,
		},
		viewOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			select: "Select",
			files: [],
			itemData: {
				crop: "",
				dieseas_name: "",
				symptoms: "• ",
				cause: "",
				nutrient_cause_type: "",
				pest_cause: null,
				nutrient_cause: null,
				file: "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
        dieseas_image:null
			},
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				// var invalidValue = false;
				// if (this.itemData.dieseas_name == "") {
				// 	invalidValue = true;
				// } else if (this.itemData.symptoms == "") {
				// 	invalidValue = true;
				// } else if (this.itemData.cause == "") {
				// 	invalidValue = true;
				// }

				// if (invalidValue) {
				// 	this.$notify({
				// 		title: "Error",
				// 		icon: "fa fa-exclamation-triangle",
				// 		type: "danger",
				// 		message: "Please enter valid data for all fields",
				// 	});
				// 	return;
				// }

				this.displayLoader = true;
				// Add new action params
				var formData = new FormData();
				formData.append("dieseas_image", this.files[0]);
        formData.append("crop", this.itemData.crop);
        formData.append("dieseas_name", this.itemData.dieseas_name);
        formData.append("symptoms", this.itemData.symptoms);
        formData.append("nutrient_cause_type", this.itemData.nutrient_cause_type);
        formData.append("pest_cause", this.itemData.pest_cause);
        formData.append("nutrient_cause", this.itemData.nutrient_cause);
          if(this.files.length==0){
				formData.delete('dieseas_image')
			}

				var actionString = "add";
				var actionObject = {
					diseaseData: formData,
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						diseaseData: this.itemData,
						index: this.editItem.index,
					};
				}

				this.$store
					.dispatch("setDiseasesDetails", {
						actionObject: actionObject,
					})
					.then(() => {
						this.$router.back();
					})
					.catch((error) => {
						this.$notify({
							title: "Failed to " + actionString + " disease",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			}
			// this.$router.back();
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.symptoms;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.symptoms += "• ";
			}
		},
		selectCrop() {
			this.itemData.crop = this.selectedCrop;
		},
		fetchNutrientCauseList() {
			this.$store
				.dispatch("fetchNutrientCauseList", this.selectedCrop)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch  nutrient cause",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchPestCauseList() {
			this.$store
				.dispatch("fetchPestCauseList", this.selectedCrop)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch  pest cause",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.itemData.dieseas_image = this.files[0].name;
		},
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.diseases.tableData;
		},
		currentItem() {
			return this.tableData.find(
				(item) => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		},
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
			if (this.selectedCrop != undefined) {
				this.fetchNutrientCauseList();
				this.fetchPestCauseList();
			}
		},
	},
	mounted() {
		if (this.selectedCrop === undefined) {
			this.$router.back();
		}
		if (this.selectedCrop != undefined) {
			this.fetchNutrientCauseList();
			this.fetchPestCauseList();
		}
		if (this.editItem) {
			this.$store
				.dispatch("fetchDiseasesDetails", {
					diseasesID: this.editItem.data.id,
				})
				.then(() => {
					this.itemData = this.currentItem;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch disease details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		}
		this.selectCrop();
	},
};
</script>
<style scoped></style>
