<template>
<div>
	<base-header class="header pt-6 pb-5 d-flex align-items-center">
		<!-- Mask -->
		<span class="mask bg-gradient-success opacity-8"></span>
		<!-- Header container -->
		<div class="container-fluid d-flex align-items-center">
			<div class="row">
				<div class="col-sm-12">
					<!-- <h1 class="display-2 text-white">Crops</h1>
					<p class="text-white mt-0 mb-0">Manage crop data here</p> -->
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--3">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-8">
								<FadeTransition :duration="200" mode="out-in">
									<div class="btn btn-danger p-2 m-1" v-if="!isRootPath" @click.prevent="$router.back()">
										<i class="fa fa-arrow-left pr-1"></i> Back
									</div>
									<div class="display-4" v-else>
										Crops
									</div>
								</FadeTransition>
							</div>
							<div class="col-4 text-right " >
								<base-dropdown class="dropdown custom-drop" :title="selectedCrop?selectedCrop.name:'Unknown Crop'" position="right">
									<template v-slot:title >
										<base-button type="secondary" class="dropdown-toggle ">
											{{selectedCrop?selectedCrop.name:'Unknown Crop' }}
										</base-button>
									</template>
									<div v-for="(crop, index) in cropList" :key="index" @click="selectedCrop = crop" class="dropdown-item ">
										{{ crop.name }}
									</div>
								</base-dropdown>
							</div>
						</div>
					</div>
					<div class="card-header">
						<FadeTransition :duration="200" mode="out-in">
							<router-view :selectedCrop="selectedCrop?selectedCrop.id:undefined"></router-view>
						</FadeTransition>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import {
	FadeTransition
} from 'vue2-transitions';

export default {
	name: 'crops',
	components: {
		FadeTransition
	},
	data() {
		return {
			selectedCrop:this.$store.state.cropData.cropLists[0]
		}
	},
	methods: {
	},
	computed: {
		cropList() {
			return this.$store.state.cropData.cropLists;
		},
		isRootPath() {
			var currentPath = this.$route.path;
			return currentPath.substring(currentPath.lastIndexOf('/') + 1) == this.$options.name;
		}
	},
	watch:{
		cropList(){
			if (this.cropList.length > 0) {
				this.selectedCrop = this.cropList[0];
			} else {
				this.selectedCrop = undefined;
			}
		}
	}
}
</script>
<style scoped>
.bg-ash {
	background-color: #e9eaed;
}
.dropdown-menu {
  padding: 0;
  box-shadow: -1.095px 6.914px 12.96px 3.04px rgba(51, 51, 51, 0.27);
  max-height: 450px;
  overflow: scroll;
}
</style>
