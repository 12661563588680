<template>
<div>
	<div class="row">
		<div class="col"> 
			<h2>Satellite Images</h2>

			<div class="table-responsive mt-4">
				<base-table class="table align-items-center table-flush" thead-classes="thead-light"
					tbody-classes="list" type="hover" :data="tableData.data">
					<template slot="columns">
						<th v-for="(header, index) in tableData.headers" :key="index">
							{{ header }}
						</th>
					</template>

					<template slot-scope="{row}">
						<td v-for="(rowItem, index) in row" :key="index">
							<div v-if="index != tableData.data.length">
								{{ rowItem }}
							</div>
							<div v-else>
								<a :href="rowItem">Link</a>
							</div>
						</td>
					</template>
				</base-table>
			</div>
		</div>
	</div>
</div>
</template>
<script>

export default {
	name: 'satellite-images',
    props: {
		selectedCrop: Number
	},
	data() {
		return {
			tableData: {
				headers: ['Name', 'Details', 'Attachments'],
				data: [
					[ 'N1', 'D1', 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png' ],
					[ 'N2', 'D2', 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png' ],
				]
			}
		}
	},
	methods: {
		
	},
    computed: {

    },
    mounted() {

    }
}
</script>
<style scoped>
</style>
