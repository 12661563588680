import { render, staticRenderFns } from "./AddIncomeAndExpenses.vue?vue&type=template&id=35303b42&scoped=true&"
import script from "./AddIncomeAndExpenses.vue?vue&type=script&lang=js&"
export * from "./AddIncomeAndExpenses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35303b42",
  null
  
)

export default component.exports