<template>
	<div>
		<base-header
			v-if="enableBanner"
			class="header mb-4 pt-7 d-flex align-items-center"
		>
			<span class="mask bg-gradient-success opacity-8"></span>
		</base-header>
		<div class="container">
			<div class="row">
				<div class="col">
					<div slot="header">
						<div class="row text-center">
							<div class="col-12">
								<h2 class="mb-4">Report</h2>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="pl-lg-4">
				<div class="row">
					<div class="col-12 form-group">
						<label class="form-control-label"
							>Name of section</label
						>
						<select
							class="rounded m-0 form-control"
							v-model="itemData.section"
						>
							<option
								v-for="(type, index) in $store.state
									.reportAnalysisData.sectionList"
								:key="index"
								:value="type"
							>
								{{ type.replace(/_/g, " ") }}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<base-input
							label="Subject"
							v-model="itemData.subject"
							input-classes="form-control"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<label class="form-control-label">Details:</label>
						<textarea
							class="form-control"
							rows="8"
							@input="watchBullets()"
							v-model="itemData.details"
						></textarea>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						@click.prevent="goBackToParent()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>

				<loader :displayLoader="displayLoader"></loader>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "report",
	props: {
		enableBanner: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			displayLoader: false,
			itemData: {
				section: "",
				subject: "",
				details: "• "
			}
		};
	},
	methods: {
		performAction() {
      if (this.inputHasErrors()) {
					return;
			}
      
			var actionObject = {
				reportData: this.itemData
			};

			this.$store
				.dispatch("setReportDetails", {
					actionObject: actionObject
				})
				.then(() => {
					this.goBackToParent();
				})
				.catch(error => {
					this.$notify({
						title: "Failed to add report",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		},
    inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.subject == "") {
				invalidValue = true;
			}
			if (this.itemData.section == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.details += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		}
	},
	computed: {},
	mounted() {
		if (this.editItem) {
			this.itemData = this.editItem.data;
		}
	}
};
</script>
<style scoped></style>
