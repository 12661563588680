<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h1 class="display-4">{{ actionString }} Sales</h1>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <base-input
                label="Date"
                input-classes="form-control"
                type="date"
                v-model="itemData.date"
                :disabled="viewOnly"
              />
            </div>
            <div class="col-6"></div>
            <div class="col-lg-6 col-md-12">
              <base-input
                label="Quantity"
                input-classes="form-control"
                type="Number"
                v-model="itemData.quantity"
                :disabled="viewOnly"
              />
            </div>
            <div class="col-lg-6 col-md-12">
              <base-input
                label="Rate"
                input-classes="form-control"
                type="Number"
                v-model="itemData.rate"
                :disabled="viewOnly"
              />
            </div>

            <div class="col form-group">
              <label class="form-control-label">Commission</label>
              <base-input
                input-classes="form-control"
                type="Number"
                v-model="itemData.commission"
                :disabled="viewOnly"
              >
              <template v-slot:addonRight>%</template>
              </base-input>
            </div>
          </div>
          <div class="button-bar text-right mt-4">
            <button
              class="btn btn-danger"
              @click.prevent="goBackToParent"
              v-if="!viewOnly"
            >
              Cancel
            </button>
            <button class="btn btn-success" @click.prevent="performAction">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-incident",
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    editItem: {
      type: Object,
      default: undefined,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemData: {
        date: "",
        quantity: "",
        rate: "",
        commission: "",
      },
    };
  },
  methods: {
    performAction() {
      if (!this.viewOnly) {
        if (this.inputHasErrors()) {
          return;
        }

        if (this.editItem == undefined) {
          this.tableData.unshift(this.itemData);
        } else {
          this.tableData.splice(this.editItem.index, 1, this.itemData);
        }
      }
      this.goBackToParent();
    },
    inputHasErrors() {
      var invalidValue = false;
      if (this.itemData.date == "") {
        invalidValue = true;
      }if (this.itemData.date.length>10) {
        invalidValue = true;
      }
      if (this.itemData.quantity == "") {
        invalidValue = true;
      }
      if (this.itemData.rate == "") {
        invalidValue = true;
      }
      if (this.itemData.commission == "") {
        invalidValue = true;
      }

      if (invalidValue) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: "Please enter valid data for all fields",
        });
      }

      return invalidValue;
    },
    goBackToParent() {
      this.$router.back();
    },
  },

  computed: {
    tableData() {
      return this.$store.state.farmerData.subscriptionData.tables[1].data;
    },
    actionString() {
      if (this.editItem) {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
  mounted() {
    if (this.editItem) {
      this.itemData = this.editItem.data;
    }
  },
};
</script>
<style lang="scss" scoped></style>
