<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h1 class="display-4">{{ actionString }} Subscription</h1>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <base-input
                label="Date"
                input-classes="form-control"
                type="date"
                v-model="itemData.created_date"
                :disabled="viewOnly"
              />
            </div>
            <div class="col-6"></div>
            <div class="col-lg-6 col-md-12 form-group">
              <label class="form-control-label">Plan</label>
              <select
                class="rounded m-0 form-control"
                v-model="itemData.subscription_plan"
                :disabled="viewOnly"
              >
                <option
                  value="farmer's assist pro"
                >
                  Farmer's assist pro
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-md-12">
              <base-input
                label="Amount"
                input-classes="form-control"
                type="Number"
                v-model="itemData.amount"
                disabled
              />
            </div>
          </div>
          <div class="button-bar text-right mt-4">
            <button
              class="btn btn-danger"
              @click.prevent="goBackToParent"
              v-if="!viewOnly"
            >
              Cancel
            </button>
            <button class="btn btn-success" @click.prevent="performAction">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-incident",
  props: {
    farmerID: {
      type: String,
      default: undefined,
    },
    editItem: {
      type: Object,
      default: undefined,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
  },
  data() {
    return {
      itemData: {
        created_date: "",
        subscription_plan: "",
        amount: 270,
      },
    };
  },
  methods: {
    performAction() {
      if (!this.viewOnly) {
        if (this.inputHasErrors()) {
          return;
        }
        this.displayLoader = true;
			this.$store.dispatch('updateSubscriptionList',{farmerID:this.$route.params.farmerID,data:this.itemData}).
      then(() => {
				this.displayLoader = false;
			}).catch((error) => {
				this.displayLoader = false;
				this.$notify({
					title: "Failed to update subscription list",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			});
      }
      this.goBackToParent();
    },
    inputHasErrors() {
      var invalidValue = false;
      if (this.itemData.created_date == "") {
        invalidValue = true;
      }
      if (this.itemData.subscription_plan == "") {
        invalidValue = true;
      }

      if (invalidValue) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: "Please enter valid data for all fields",
        });
      }

      return invalidValue;
    },
    goBackToParent() {
      this.$router.back();
    },
  },

  computed: {
    tableData() {
      return this.$store.state.farmerData.subscriptionData.tables[0].data;
    },
    actionString() {
      if (this.editItem) {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
  mounted() {
    if(this.selectedLandID ===undefined){
      this.goBackToParent()
    }
    if (this.editItem) {
      this.itemData = this.editItem.data;
    }
  },
};
</script>
<style lang="scss" scoped></style>
