<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">
							Income And Expenses {{ actionString }}
						</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Date"
								input-classes="form-control"
								type="date"
								v-model="itemData.added_date"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<base-input
								label="Cost"
								input-classes="form-control"
								type="number"
								v-model="itemData.amount"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Income/Expense</label
							>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.cost_type"
								:disabled="viewOnly"
							>
								<option selected disabled value="">Choose</option>
								<option value="income">Income</option>
								<option value="expense">Expense</option>
							</select>
						</div>
					</div>
          <div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Cost Category</label
							>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.category"
								:disabled="viewOnly"
							>
								<option
									v-for="(category, index) in costCategories"
									:key="index" :value="category.id"
								>
									{{ category.name }}
								</option>
							</select>
						</div>
					</div>
					
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Description</label
							>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.description"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-income-and-expenses",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		selectedLandID: {
			type: Number
		},
		selectedCrop: {
			type: Number
		}
	},
	data() {
		return {
			itemData: {
				added_date: "",
				added_time: "12:12",
				amount: "",
				cost_type: "",
				description: "• ",
				memo: "String",
				land: this.selectedLandID,
				category: 2
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					incomeExpenseData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						incomeExpenseData: this.itemData,
						index: this.editItem.index
					};
				}
				this.$store
					.dispatch("setIncomeAndExpensesDetails", {
						actionObject: actionObject,
						farmerID: this.farmerID
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title: "Failed to " + actionString + " expert",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.added_date == "") {
				invalidValue = true;
			}if (this.itemData.added_date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.amount == "") {
				invalidValue = true;
			}
			if (this.itemData.cost_type == "") {
				invalidValue = true;
			}
			if (this.itemData.description == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.description;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.description += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		fetchIncomeAndExpensesDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchIncomeAndExpensesDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch income and expense detail",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
		fetchCostCategoriesList() {
			this.$store
				.dispatch("fetchCostCategoriesList",this.itemData.cost_type)
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch cost categories",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.expenditureData.tables[0].data;
		},
    costCategories() {
      return this.$store.state.farmerData.expenditureData.costCategories;
    },
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch:{
		'itemData.cost_type'(){
			this.fetchCostCategoriesList()
		}
	},
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
	},
	mounted() {
		if (this.selectedCrop === undefined || this.selectedLandID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}
		if (this.editItem) {
			this.fetchIncomeAndExpensesDetails();
			this.itemData = this.currentItem;
		}
	}
};
</script>
<style lang="scss" scoped></style>
