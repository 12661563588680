<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">
							{{ actionString }} Recommendations
						</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Date"
								input-classes="form-control"
								type="date"
								v-model="itemData.date"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<base-input
								label="Recommendation"
								input-classes="form-control"
								v-model="itemData.recommendation"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row form-group">
						<div v-if="!viewOnly" class="col">
							<label class="form-control-label">Image file</label>
							<input
								class="form-control"
								@change="onFileChange"
								type="file"
							/>
						</div>
						<div v-else-if="itemData.image" class="col">
							<label class="form-control-label d-block">Image preview</label>
							<img :src="itemData.image.full_size" width="500px" />
						</div>
					</div>

					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Section</label>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.section"
								:disabled="viewOnly"
							>
								<option
									v-for="(type, index) in $store.state
										.farmerData.recommendationData
										.sectionList"
									:key="index"
									:value="type.toLowerCase()"
								>
									{{ type[0].toUpperCase() + type.slice(1) }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Details</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-recommendation",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		crop: {
			type: Number,
			default: undefined,
		},
		landID: {
			type: Number,
		},
		editItem: {
			type: Object,
			default: undefined,
		},
		viewOnly: {
			type: Boolean,
			default: false,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
	},
	data() {
		return {
			files:[],
			itemData: {
				date: "",
				imageFile: undefined,
				recommendation: "",
				section: this.$store.state.farmerData.recommendationData.sectionList[0],
				details: "• ",
				land_id:null,
				crop_id:null
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				const formData = new FormData();
				if (this.itemData.imageFile)
					formData.append("image", this.files[0]);
				formData.append("date", this.itemData.date);
				formData.append("id", this.itemData.id);
				formData.append("added_date", this.itemData.date);
				formData.append("recommendation", this.itemData.recommendation);
				formData.append("section", this.itemData.section);
				formData.append("details", this.itemData.details);
				formData.append("land_id",this.selectedLandID);
				formData.append("crop_id",this.selectedCrop)

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					recommData: formData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						recommData: formData,
						index: this.editItem.index
					};
				}

				this.$store
					.dispatch("setRecommendation", {
						farmerID: this.farmerID,
						actionObject,
					})
					.then(() => {
						this.goBackToParent();
						
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " recommendation",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files || this.files.length === 0) return;
			this.itemData.imageFile = this.files[0];
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.date == "") {
				invalidValue = true;
			}if (this.itemData.date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.recommendation == "") {
				invalidValue = true;
			}
			if (this.itemData.section == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.details += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
			// this.$store.dispatch("fetchRecommendations", { farmerID: this.farmerID, landID: this.selectedLandID,crop:this.selectedCrop ,searchQuery:"",startDate:null,endDate:null });
		},
		fetchRecommendationDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchRecommendationDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
					this.itemData.land = this.landID;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch recommendation details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.recommendationData
				.recommendations;
		},
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				}
	},

	mounted() {
		if (this.crop === undefined || this.landID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}

		if (this.editItem) {
			this.fetchRecommendationDetails();
			this.itemData = this.currentItem;
		}
	}
};
</script>
<style lang="scss" scoped></style>
